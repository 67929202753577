define(['app', '$console', '$window', 'siteObj'], (app, $console, $window, $siteObj) => {
  const revieveVto = () => {
    const component = {};

    const _config = {
      src: $siteObj.config.revieve.revieveVtoSrc,
      integrityHash: $siteObj.config.revieve.revieveVtoIntegrityHash,
      enableRevieve: $siteObj.config.revieve.revieveVtoEnabled,
      productID: $siteObj.productID ? $siteObj.productID : '',
      getProductIdFromUrl: false, // this is just for testing when the product id is not available
      pageType: '',
      currentPage: '',
      currentPartnerId: '',
      productArray: [],
      itemsCount: 0,
      basket: $siteObj.siteBasketURL,
      revieveLiveArAvailable: false, // boolean
      replacementArea: '',
      revieveVtoContainer: '',
      selectors: {
        buttons: '[data-revieve-button]',
        productImageContainer: '.athenaProductPage_firstColumn',
        replacementImageArea: '.athenaProductImageCarousel',
        revieveVtoContainer: '.revieve-vto-container'
      },
      subscribeChannels: {
        newAddToBasketButton: 'productAddToBasketButton/newButton',
        newImage: 'productSingleImage/newImage',
      },
      baseLocale : 'en-gb',
      localeMap : {
        'ja' : 'jp',
        'zh' : 'cn'
      },
      revieveConfig: {
        partner_id: '',
        locale: 'en',
        env: $siteObj.config.revieve.revieveVtoEnvironment,
        storageKey: 'revieve',
        storageTtl: 7 * 24 * 60,
        onClose: () => { component.revieveClose() },
        onClickProduct: (product) => {component.revieveProductClicked(product) },
        onCheckout: (data) => { component.revieveCheckout(data) },
        onAddToCart: (products) => { $siteObj.debugFlag && $console.log('add to cart'); component.addToBasketInit(products) },
      }
    }

    const _extractProductIdFromUrl = (url) => {
      const urlParts = url.split('/');
      const productId = urlParts.find(part => part.includes('.html')).split('?').find(part => part.endsWith('.html')).split('.')[0];

      if (!productId) return '';

      return productId;
    }

    const _bindEvents = () => {
      component.buttonEvents();
    }

    const _buttonEvents = () => {
      component.buttons = component.element.querySelectorAll('[data-revieve-button]');
      component.buttons.forEach(button => {
        button.addEventListener('click', component.revieveShow);
      });
    }

    const _init = (element) => {
      component.element = element;
      component.getLocale();
      component.checkPageType();
      component.bindEvents();
      component.subscribe();
      component.revieveLiveArInit();
    }

    const _toggleReplacementArea = () => {
      const imageWrapper = document.querySelector('.athenaProductPage_firstColumn');
      if (!imageWrapper) return;
      if (imageWrapper.classList.contains('athenaProductPage_hide_carousel')) {
        imageWrapper.classList.remove('athenaProductPage_hide_carousel');
        component.config.revieveVtoContainer.classList.remove('revieve-vto-container_active');
      } else {
        imageWrapper.classList.add('athenaProductPage_hide_carousel');
        component.config.revieveVtoContainer.classList.add('revieve-vto-container_active');
      }
      //imageWrapper && imageWrapper.classList.add('athenaProductPage_hide_carousel');
      //component.config.revieveVtoContainer && component.config.revieveVtoContainer.classList.add('revieve-vto-container_active');
    }

    const _reveiveUpdateConfig = () => { // to do make this for the live ar as well
      const renderTo = document.querySelector('#revieve-vto-container');
      component.config.revieveVtoContainer = renderTo;
      component.config.revieveConfig.renderTo = renderTo;
    }

    const _revieveUpdateProductId = () => {
      const pageType = $siteObj.type === 'product';
      if (!pageType) {return}

      const productId = document.querySelector('.productAddToBasket').getAttribute('data-product-id')
      productId && (component.config.productID = productId);
    }

    const _getLocale = () => {
      let _baseLocale = document.querySelector('html').getAttribute('lang');

      !_baseLocale && (_baseLocale = component.config.baseLocale);
      const _locale = _baseLocale.split('-')[0];

      component.config.revieveConfig.locale = component.config.localeMap[_locale] || _locale;
    }

    const _checkPageType = () => {
      component.config.pageType = $siteObj.type ? $siteObj.type : '';
    }

    const _addToBasketGetLastAddedItemData = (simpleBasket) => {
      let lastAddedItem = simpleBasket.lastAddedItem;

      let data = {
        worthValue: lastAddedItem.worthValue,
        productId: lastAddedItem.productId,
        productTitle: lastAddedItem.productTitle,
        productUrl: lastAddedItem.productURL,
        productQuantity: simpleBasket.lastAddedQuantity,
        productQuantityRequested: simpleBasket.lastAddedQuantityRequested,
        productPrice: lastAddedItem.price,
        basketTotalPrice: simpleBasket.totalprice,
        recommendations: simpleBasket.recommendations,
        basketTotalItems: simpleBasket.totalUnits,
        message: simpleBasket.message,
        lastAddedMaxPerOrder: lastAddedItem.maxPerOrder,
        loyaltySchemePoints: simpleBasket.loyaltySchemePoints,
        productImageUrl: ''
      };

      if (lastAddedItem.productImages && lastAddedItem.productImages.largeproduct) {
        data.productImageUrl = lastAddedItem.productImages.largeproduct;
      }

      return data;
    };

    const _addToBasketSuccess = (data) => {
      app.publish('globalBasketItemsCount/updateGlobalBasketItemsCount');

      let jsonData = JSON.parse(data);
      let modalData = component.addToBasketGetLastAddedItemData(jsonData.simpleBasket);
      app.publish('productQuickbuy/hideModal', '', false);
      app.publish('addedToBasketModal/productAdded', modalData, false);

      app.publish('BasketItemAdded', jsonData);
      $siteObj.debugFlag && $console.error('Basket Item Added', jsonData)

      component.addToBasketUpdateDataLayer(jsonData);
    };

    const _addToBasketUpdateDataLayer = (jsonData) => {
      if (jsonData.simpleBasket.lastAddedItem) {
        let lastAddedItemSku = jsonData.simpleBasket.lastAddedItem.productId;
        if (window.dataLayer.length) {
          window.dataLayer[0].lastAddedItemSku = lastAddedItemSku;
        }
      }
    }

    const _addToBasketError = (error) => {
      $siteObj.debugFlag && $console.error('add to basket error', error);

      app.publish('productQuickbuy/stopLoading', '', false);
      app.publish('productQuickbuy/showError', '', false);
      app.publish('addedToBasketModal/showError', '', false);

    };

    const _addToBasketInit = (products) => {
      $siteObj.debugFlag && $console.log('add to basket', products);
      if (!products) return;

      component.config.itemsCount = products.length;
      const productsToAdd = products.bundle ? products.bundle.products : products;
      component.addToBasketLoop(productsToAdd);
    }

    const _addToBasketLoop = (products) => {
      if (typeof products === 'object') {
        products = component.addToBasketConvertToArray(products);
      }
      products.forEach((product, index) => {
        $siteObj.debugFlag && $console.log('index', index);
        component.config.productArray.push(product);
        component.addToBasketData(component.addToBasketGetQueryObject(product));
      });
    }

    const _addToBasketConvertToArray = (products) => {
      if (Array.isArray(products)) return products;
      return [products];
    }

    const _addToBasketData = (queryObj) => {

      if (component.config.getProductIdFromUrl) {
        $siteObj.debugFlag && $console.log('get product id from url');
        queryObj.productId = component.extractProductIdFromUrl(queryObj.url);
      }

      let productData = component.addToBasketQuerify(queryObj);

      app.ajax.get({
        url: '/basketinterface.json?' + productData,
        success: component.addToBasketSuccess,
        error: component.addToBasketError
      });
    };

    const _addToBasketQuerify = obj => Object.entries(obj).map(([key, value]) => `${key}=${value}`).join('&');

    const _addToBasketGetQueryObject = (item) => {
      return {
        productId: component.addToBasketRemoveRevieveDash(item.id),
        siteId: $siteObj.siteID,
        siteDefaultLocale: $siteObj.siteDefaultLocale,
        siteSubsiteCode: $siteObj.subsiteCode,
        variationLength: 0,
        quantity: 1,
        fromWishlist: false,
        url: item.url, // used for testing the when the product id is not available
      }
    };

    // removal of value after the dash in the revieve SKU
    const _addToBasketRemoveRevieveDash = (revieveSKU) => {
      return revieveSKU.includes('-') && revieveSKU.split('-')[0] || revieveSKU;
    }

    const _revieveLoad = (show, fromClickEvent = false ) => {

      if (window.Revieve && window.Revieve.API) {
        $window.Revieve.API.unmount();
      }

      var rv = document.createElement('script');
      rv.src = component.config.src;
      rv.type = 'text/javascript';
      rv.async = 'true';
      rv.env = component.config.revieveConfig.env;
      component.config.integrityHash && (rv.integrity = component.config.integrityHash);
      rv.crossOrigin = 'anonymous';
      rv.onload = rv.onreadystatechange = function () {
        var rs = this.readyState;
        if (rs && rs != 'complete' && rs != 'loaded') return;

        $window.Revieve.Init(component.config.revieveConfig, () => {
          (component.config.revieveLiveArAvailable && fromClickEvent) && component.toggleReplacementArea();
          component.config.revieveLiveArAvailable && component.revieveLiveArAdd();
          show && $window.Revieve.API.show();
        });

      };
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(rv, s);
    }

    const _revieveProductClicked = (product) => {
      $siteObj.debugFlag && $console.log('product clicked', product);
      $window.location.href = product.url;
    }

    const _revieveInit = () => {
      $siteObj.debugFlag && $console.log('init revieve');
      $window.Revieve && $window.Revieve.Init(component.config.revieveConfig, () => { });
    }

    const _revieveShow = (event) => {
      event.preventDefault();
      // check if the event comes from vto button
      if (event.target.hasAttribute('data-revieve-vto')) {
        $siteObj.debugFlag && $console.log('vto button clicked');
      }

      if ($window.innerWidth < 900) {
        window.scrollTo(0, 0);
      }
      
      component.revieveUpdateProductId();
      component.revieveGetPartnerId(event);
      component.revieveLoad(true,true);
      $window.Revieve && component.revieveLiveArAdd();
    }

    const _revieveClose = () => {
      // check if we are closing the pdp vto
      $siteObj.debugFlag && $console.log('close revieve');
      // we might want to use restart here to restart the experience
      if (component.config.pageType === 'product') {
        component.revieveUnmount(); // seems to cause a buy if used with the modal version 
        component.toggleReplacementArea()
      }
    }

    const _revieveUnmount = () => {
      $window.Revieve && $window.Revieve.API.unmount();
      if (component.config.pageType === 'product') {
        $window.Revieve && component.revieveInit();
      }
    }

    const _revieveUpdateCurrentPartnerId = (partnerId) => {
      component.config.currentPartnerId = partnerId;
    }

    const _revieveGetPartnerId = (event) => {// possible combine the revieveAr function to get partner Id
      // gets the partner ID from the button's data attribute
      $siteObj.debugFlag && $console.log('get partner id', event.target.dataset.partnerId);
      let partner_id = event.target.dataset.partnerId;

      if (partner_id === undefined) return; // if the button doesn't have a partner id, do nothing

      partner_id = partner_id.trim();

      if (partner_id !== component.config.currentPartnerId) {
        $siteObj.debugFlag && $console.log('partner id changed');
        component.config.revieveConfig.partner_id = partner_id;
      }

      $siteObj.debugFlag && $console.log('current:', component.config.currentPartnerId, 'clicked:', partner_id);
      component.config.revieveConfig.partner_id = partner_id
      component.revieveUpdateCurrentPartnerId(partner_id);
    }
    const _revieveCheckout = (data) => {
      if(!component.config.basket) {return}
      $siteObj.debugFlag && $console.log('revieve checkout', data);
      $window.location.href = component.config.basket;
    }

    const _revieveLiveArUpdate = (productId, tryOnClicked = false) => {
      $siteObj.debugFlag && $console.log('product id', productId);
      component.config.productID = productId;
      component.revieveLiveArCheckTryOnIsAvailable();
      component.config.revieveLiveArAvailable && component.revieveLiveArShowButton();
      $window.Revieve && $window.Revieve.API.liveAR.resetTryOnProducts();
      component.config.revieveLiveArAvailable && component.revieveLiveArAdd(productId);
      tryOnClicked && component.toggleReplacementArea();
    }


    const _revieveLiveArCta = (event) => {
      const product_id = component.config.productID;
      if (component.config.pageType !== 'product' || !event.target.hasAttribute('data-revieve-live-ar') || product_id.length < 1) return;
      component.config.partner_id = component.config.currentPartnerId;

      component.revieveLiveArCheckTryOnIsAvailable();
      component.config.revieveLiveArAvailable && component.revieveLiveArAdd();
    }

    /*
    Revieve init is for the initial load of the product page
    if we don't load revieve we can't run the plugin correctly on the PDP
    */
    const _reveiveLiveArInit = () => {
      component.revieveLiveArCtaAvailable();

      if (component.config.revieveLiveArAvailable) {
        component.config.revieveConfig.partner_id = component.config.revieveLiveArAvailable.dataset.partnerId.trim();
        component.revieveUpdateProductId();
        component.revieveLiveArCreateVtoContainer();
        component.revieveLoad(false);
        component.revieveLiveArShowButton();
      }

    }

    const _revieveLiveArCheckTryOnIsAvailable = () => {
      if (!$window.Revieve) return;
      $window.Revieve.API.liveAR.isTryOnAvailable({
        'product_id' : component.config.productID,
        'env' : component.config.revieveConfig.env,
        'partner_id' : component.config.revieveConfig.partner_id }, (available) => {
        component.config.revieveLiveArAvailable = available;
      });
    }
    const _revieveLiveArCreateVtoContainer = () => {

      if (!component.config.revieveLiveArAvailable ) {return}

      const vtoContainer = document.createElement('div');
      vtoContainer.id = 'revieve-vto-container';
      vtoContainer.classList.add('revieve-vto-container');
      vtoContainer.setAttribute('id', 'revieve-vto-container');

      const productImageContainer = document.querySelector(component.config.selectors.productImageContainer);

      productImageContainer && productImageContainer.insertAdjacentElement('afterbegin',vtoContainer);
      component.revieveUpdateConfig();
    }

    const _revieveLiveArShowButton = () => {
      const buttonLiveAr = document.querySelector('[data-revieve-live-ar]');
      component.config.revieveLiveArAvailable && buttonLiveAr ? buttonLiveAr.removeAttribute('style') : buttonLiveAr.setAttribute('style', 'display: none');
    }

    const _revieveLiveArAdd = (productId) => {
      if (!$window.Revieve) return;
      // if productId is provided then the add to basket button has been replaced
      const product_id = productId ? productId : component.revieveUpdateProductId();
      productId && $window.Revieve.API.liveAR.addTryOnProduct(product_id);
    }

    const _revieveLiveArCtaAvailable = () => {
      const revieveLiveArButton = document.querySelector('[data-revieve-live-ar]');
      component.config.revieveLiveArAvailable = revieveLiveArButton;
    }

    const _subscribe = () => {
      app.subscribe(component.config.subscribeChannels.newAddToBasketButton, component.revieveLiveArUpdate);
    }

    component.addToBasketConvertToArray = _addToBasketConvertToArray;
    component.addToBasketData = _addToBasketData;
    component.addToBasketError = _addToBasketError;
    component.addToBasketGetLastAddedItemData = _addToBasketGetLastAddedItemData;
    component.addToBasketGetQueryObject = _addToBasketGetQueryObject;
    component.addToBasketInit = _addToBasketInit;
    component.addToBasketLoop = _addToBasketLoop;
    component.addToBasketQuerify = _addToBasketQuerify;
    component.addToBasketSuccess = _addToBasketSuccess;
    component.addToBasketUpdateDataLayer = _addToBasketUpdateDataLayer;
    component.addToBasketRemoveRevieveDash = _addToBasketRemoveRevieveDash
    
    component.bindEvents = _bindEvents;
    component.buttonEvents = _buttonEvents;
    component.checkPageType = _checkPageType;
    component.config = _config;
    component.extractProductIdFromUrl = _extractProductIdFromUrl;
    component.getLocale = _getLocale;
    component.toggleReplacementArea = _toggleReplacementArea;
    component.init = _init;

    component.revieveCheckout = _revieveCheckout;
    component.revieveClose = _revieveClose;
    component.revieveGetPartnerId = _revieveGetPartnerId;
    component.revieveInit = _revieveInit;
    component.revieveLoad = _revieveLoad;
    component.revieveProductClicked = _revieveProductClicked;
    component.revieveShow = _revieveShow;
    component.revieveUnmount = _revieveUnmount;
    component.revieveUpdateCurrentPartnerId = _revieveUpdateCurrentPartnerId;
    component.revieveUpdateConfig = _reveiveUpdateConfig;
    component.revieveLiveArUpdate = _revieveLiveArUpdate;
    component.revieveUpdateProductId = _revieveUpdateProductId;

    component.revieveLiveArAdd = _revieveLiveArAdd;
    component.revieveLiveArCta = _revieveLiveArCta;
    component.revieveLiveArCreateVtoContainer = _revieveLiveArCreateVtoContainer;
    component.revieveLiveArShowButton = _revieveLiveArShowButton;
    component.revieveLiveArCheckTryOnIsAvailable = _revieveLiveArCheckTryOnIsAvailable;
    component.revieveLiveArInit = _reveiveLiveArInit;
    component.revieveLiveArCtaAvailable = _revieveLiveArCtaAvailable;

    component.subscribe = _subscribe;
    return component;
  }

  return revieveVto;
});

